import ViewScheduledPowerConsumersSortedByEnergyRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/view-scheduled-power-consumers-sorted-by-energy-request-dto";
import {
  SpaceClustersResponseDTO,
  ViewConsumedSpaceClusterByYearAndMonthResponseDTO,
  ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import ViewCurrentEnergySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-current-energy-source-response-dto";
import ViewCurrentGenerationAndUsageDTO from "../../../shared/oversight-core/dtos/response-dtos/view-current-generation-and-usage-dto";
import ViewEnergyUsageBySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-usage-by-source-response-dto";
import ViewLatestEnergyStatisticsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-latest-energy-statistics-response-dto";
import ViewScheduledPowerConsumersSortedByEnergyResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-scheduled-power-consumers-sorted-by-energy-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const dashboardAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpaceClustersXY: builder.query<SpaceClustersResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `space-cluster/with-power-generators/list`,
          method: "GET",
        };
      },
    }),
    getSpaceClusterEnergyWithComparison: builder.query<
      ViewSpaceClusterEnergyWithComparisonResponseDTO,
      { spaceClusterId: string; zoneOffset: string }
    >({
      transformErrorResponse,
      query({ spaceClusterId, zoneOffset }) {
        return {
          url: `space-cluster/${spaceClusterId}/energy/with-comparison/view`,
          method: "GET",
          params: {
            zoneOffset,
          },
        };
      },
    }),
    getConsumedSpaceClusterByYearAndMonth: builder.query<
      ViewConsumedSpaceClusterByYearAndMonthResponseDTO,
      { spaceClusterId: string; year: number; month: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/consumed-energy/by-year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
    viewPowerConsumersWithHighConsumption: builder.query<
      ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
      ViewScheduledPowerConsumersSortedByEnergyRequestDTO
    >({
      transformErrorResponse,
      query({
        clusterId,
        sortingOrder,
        limit,
        viewType,
        energyViewType,
        year,
        month,
        zoneOffset,
      }) {
        return {
          url: `space-cluster/${clusterId}/power-consumer-usage-schedule/sort-by-energy/view`,
          method: "GET",
          params: {
            sortingOrder,
            limit,
            viewType,
            energyViewType,
            year,
            month,
            zoneOffset,
          },
        };
      },
    }),
    viewSpaceClusterConsumptionByYearAndMonth: builder.query<
      ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
      { spaceClusterId: string; year: number; month: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/consumption/by-year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
    viewLatestEnergyStatisticData: builder.query<
      ViewLatestEnergyStatisticsResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-generation/latest-stats/view`,
          method: "GET",
        };
      },
    }),
    viewCurrentEnergySourceData: builder.query<
      ViewCurrentEnergySourceResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-generation/current-energy-source/view`,
          method: "GET",
        };
      },
    }),
    viewEnergyUsageBySource: builder.query<
      ViewEnergyUsageBySourceResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-usage/by-source/view`,
          method: "GET",
        };
      },
    }),
    viewCurrentGenerationAndUsage: builder.query<
      ViewCurrentGenerationAndUsageDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-generation/current/generation-and-usage`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetSpaceClustersXYQuery,
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyGetConsumedSpaceClusterByYearAndMonthQuery,
  useLazyViewPowerConsumersWithHighConsumptionQuery,
  useLazyViewSpaceClusterConsumptionByYearAndMonthQuery,
  useLazyViewLatestEnergyStatisticDataQuery,
  useLazyViewCurrentEnergySourceDataQuery,
  useLazyViewEnergyUsageBySourceQuery,
  useLazyViewCurrentGenerationAndUsageQuery,
} = dashboardAPI;
