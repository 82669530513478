import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyViewBatteryBackupQuery,
  useLazyViewBatteryInformationQuery,
  useLazyViewEnergyGenerationQuery,
  useLazyViewLiveEnergyGenerationDataQuery,
  useLazyViewMonthlyTotalEnergyGenerationQuery,
} from "../../../redux/api/generation/generationAPI";
import {
  selectGenerationFilter,
  setGenerationFilter,
} from "../../../redux/features/generation-filter/generation-filter-slice";
import ViewMonthlyTotalEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/ViewMonthlyTotalEnergyGenerationResponseDTO";
import ViewBatteryBackupInformationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-battery-backup-information-response-dto";
import ViewBatteryInformationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-battery-information-response-dto";
import ViewDailyEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-daily-energy-generation-response-dto";
import ViewLiveEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-live-energy-generation-response-dto";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import { EEnergySourceType } from "../../../shared/oversight-core/enums/energy-source-type";
import { EPowerMeasureType } from "../../../shared/oversight-core/enums/power-measure-type";
import { EUnitType } from "../../../shared/oversight-core/enums/unit-type";
import { LiveEnergyGenerationView } from "../../../shared/oversight-core/interfaces/live-energy-generation-view";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import EnergySourceInfoCardWithIndicator from "../../../shared/oversight-core/ui-elements/energy-source-info-card-with-indicator/energy-source-info-card-with-indicator";
import EnergySourceInfo from "../../../shared/oversight-core/ui-elements/energy-source-info/energy-source-info";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { energySourceIconType } from "../../../shared/oversight-core/utils/energy-source-icon-type";
import { findEnergySourceType } from "../../../shared/oversight-core/utils/find-energy-source-type";
import Helper from "../../../shared/oversight-core/utils/helpers";
import { powerConvertor } from "../../../shared/oversight-core/utils/power-convertor";
import { formatHour } from "../../../shared/oversight-core/utils/time-utils";
import ExpectedPowerValue from "./components/expected-power-value/expected-power-value";
import GenerationFilter from "./components/generation-filter/generation-filter";

const unitTypes: Option[] = [
  {
    value: EUnitType.POWER,
    label: "Power(kW)",
  },
  {
    value: EUnitType.ENERGY,
    label: "Energy(kWh)",
  },
];

const monthList: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
interface ChartData {
  solarData: (number | null)[];
  windTurbineData: (number | null)[];
  generatorData: (number | null)[];
  labels: string[];
}

const defaultChartDataValues = {
  solarData: [],
  windTurbineData: [],
  generatorData: [],
  labels: [],
};

const desiredOrder = [
  EEnergySourceType.SOLAR_PV,
  EEnergySourceType.WIND_TURBINE,
  EEnergySourceType.GENERATOR,
];

const Generation = () => {
  const [selectedUnitType, setSelectedUnitType] = useState<Option>({
    ...unitTypes[0],
  });
  const [liveGenerationDate, setLiveGenerationData] = useState<
    LiveEnergyGenerationView[]
  >([]);
  const [totalEnergyGeneration, setTotalEnergyGeneration] = useState<{
    [key: string]: number;
  }>({});
  const [batteryData, setBatteryData] =
    useState<ViewBatteryInformationResponseDTO>();
  const [chartData, setChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [batteryBackupChartData, setBatteryBackupChartData] = useState<
    number[]
  >([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isTotalGenerationFilter, setIsTotalGenerationFilter] = useState(false);
  const generationFilterStore = useSelector(selectGenerationFilter);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const dispatch = useDispatch();

  const [
    triggerLiveEnergyGenerationData,
    { isFetching: isFetchingLiveEnergyGenerationData },
  ] = useLazyViewLiveEnergyGenerationDataQuery();
  const [
    triggerViewMonthlyTotalEnergyGeneration,
    { isFetching: isFetchingViewMonthlyTotalEnergyGeneration },
  ] = useLazyViewMonthlyTotalEnergyGenerationQuery();
  const [
    triggerViewBatteryInformation,
    { isFetching: isFetchingViewBatteryInformation },
  ] = useLazyViewBatteryInformationQuery();
  const [
    triggerEnergyGenerationData,
    { isFetching: isFetchingEnergyGenerationData },
  ] = useLazyViewEnergyGenerationQuery();
  const [
    triggerBatteryBackupData,
    { isFetching: isFetchingBatteryBackupData },
  ] = useLazyViewBatteryBackupQuery();

  useEffect(() => {
    triggerLiveEnergyGenerationData()
      .unwrap()
      .then((res: ViewLiveEnergyGenerationResponseDTO) => {
        setLiveGenerationData(res.liveEnergyGenerationViews);
        setIsFirstTime(false);
      })
      .catch(() => {
        setLiveGenerationData([
          {
            powerGeneratorType: "",
            generatedEnergy: 0,
            hourlyPredictedEnergy: 0,
          },
        ]);
      });
  }, [triggerLiveEnergyGenerationData]);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerLiveEnergyGenerationData()
        .unwrap()
        .then((res: ViewLiveEnergyGenerationResponseDTO) => {
          setLiveGenerationData(res.liveEnergyGenerationViews);
          setIsFirstTime(false);
        })
        .catch(() => {
          setLiveGenerationData([
            {
              powerGeneratorType: "",
              generatedEnergy: 0,
              hourlyPredictedEnergy: 0,
            },
          ]);
        });
    }, 20000);
    return () => clearInterval(interval);
  }, [triggerLiveEnergyGenerationData]);

  useEffect(() => {
    triggerViewMonthlyTotalEnergyGeneration({
      year: new Date(
        generationFilterStore.selectedTotalGenerationDate
      ).getFullYear(),
      month:
        new Date(generationFilterStore.selectedTotalGenerationDate).getMonth() +
        1,
    })
      .unwrap()
      .then((res: ViewMonthlyTotalEnergyGenerationResponseDTO) => {
        setTotalEnergyGeneration(res.totalEnergyGeneration);
      })
      .catch(() => {
        setTotalEnergyGeneration({});
      });
  }, [
    triggerViewMonthlyTotalEnergyGeneration,
    generationFilterStore.selectedTotalGenerationDate,
  ]);

  useEffect(() => {
    triggerViewBatteryInformation()
      .unwrap()
      .then((res: ViewBatteryInformationResponseDTO) => {
        setBatteryData(res);
      })
      .catch(() => {
        setBatteryData({
          batteryPercentage: 0,
          batteryCapacity: 0,
          expectedBatteryCharge: 0,
        });
      });
  }, [triggerViewBatteryInformation]);

  useEffect(() => {
    const triggerEnergyGenerationDataObject: {
      year?: number;
      month?: number;
      day?: number;
      unitType: EUnitType;
    } = {
      unitType: selectedUnitType.value as EUnitType.ENERGY,
      year: new Date(generationFilterStore.selectedDate).getFullYear(),
      month: new Date(generationFilterStore.selectedDate).getMonth() + 1,
    };

    if (generationFilterStore.selectedDateType === EDateTypes.DAILY) {
      triggerEnergyGenerationDataObject.day = new Date(
        generationFilterStore.selectedDate
      ).getDate();
    }

    triggerEnergyGenerationData(triggerEnergyGenerationDataObject)
      .unwrap()
      .then((res: ViewDailyEnergyGenerationResponseDTO) => {
        const solarPower = Object.entries(res.totalSolarGeneration).map(
          (item) => (item[1] ? item[1] / 1000 : item[1])
        );
        const windPower = Object.entries(res.totalWindTurbineGeneration).map(
          (item) => (item[1] ? item[1] / 1000 : item[1])
        );
        const generatorPower = Object.entries(res.totalGeneratorGeneration).map(
          (item) => (item[1] ? item[1] / 1000 : item[1])
        );
        const labels = Object.entries(res.totalSolarGeneration).map((item) =>
          generationFilterStore.selectedDateType === EDateTypes.DAILY
            ? formatHour(Number(item[0]))
            : item[0]
        );

        setChartData({
          solarData: solarPower,
          windTurbineData: windPower,
          generatorData: generatorPower,
          labels: labels,
        });
      })
      .catch(() => {
        setChartData({ ...defaultChartDataValues });
      });
  }, [
    triggerEnergyGenerationData,
    selectedUnitType.value,
    generationFilterStore.selectedDateType,
    generationFilterStore.selectedDate,
  ]);

  useEffect(() => {
    triggerBatteryBackupData()
      .unwrap()
      .then((res: ViewBatteryBackupInformationResponseDTO) => {
        const batteryBackup = Object.values(res.hourlyBatteryPercentage);

        setBatteryBackupChartData(batteryBackup);
      })
      .catch(() => {
        setBatteryBackupChartData([]);
      });
  }, [triggerBatteryBackupData]);

  const onFilter = (
    selectedDateType: EDateTypes,
    selectedDate: Date,
    selectedTotalGenerationDate: Date
  ) => {
    dispatch(
      setGenerationFilter({
        selectedDate: selectedDate.getTime(),
        selectedTotalGenerationDate: selectedTotalGenerationDate.getTime(),
        selectedDateType,
      })
    );
    setShowFilter(false);
  };

  const onClearAll = () => {
    dispatch(
      setGenerationFilter({
        selectedDate: new Date().getTime(),
        selectedTotalGenerationDate: new Date().getTime(),
        selectedDateType: EDateTypes.DAILY,
      })
    );
    setShowFilter(false);
  };

  return (
    <>
      <div className="container-white px-4">
        <Row className="align-items-center">
          <Col>
            <Row>
              <Col className="text-dark font-size-14 font-weight-500">
                Total Generation for&nbsp;
                {moment(
                  generationFilterStore.selectedTotalGenerationDate
                ).format("MMMM")}
              </Col>
            </Row>
            <Row className="mt-3 gap-3 gap-lg-5">
              {Object.keys(totalEnergyGeneration).length === 0 ? (
                <div className="text-light font-size-12 font-weight-400">
                  No total generation of this month
                </div>
              ) : (
                <>
                  {Object.entries(totalEnergyGeneration)
                    .reverse()
                    .map((item, index) => {
                      return (
                        <Col key={index} className="col-auto">
                          <EnergySourceInfo
                            title={findEnergySourceType(
                              item[0] as EEnergySourceType
                            )}
                            value={Number(
                              Helper.roundTo2(
                                powerConvertor(
                                  item[1],
                                  EPowerMeasureType.KILO_WATT
                                )
                              )
                            )}
                            icon={energySourceIconType(
                              item[0] as EEnergySourceType
                            )}
                            isFetching={
                              isFetchingViewMonthlyTotalEnergyGeneration
                            }
                          />
                        </Col>
                      );
                    })}
                </>
              )}
            </Row>
          </Col>
          <Col className="col-auto">
            <Row className="align-items-center">
              <Col className="text-primary font-size-12 font-weight-500">
                {moment(
                  generationFilterStore.selectedTotalGenerationDate
                ).format("MMMM")}{" "}
                {new Date(
                  generationFilterStore.selectedTotalGenerationDate
                ).getFullYear()}
              </Col>
              <Col className="col-auto">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => {
                    setShowFilter(true);
                    setIsTotalGenerationFilter(true);
                  }}
                  badgeValue={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="container-white px-4 mt-4">
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <ActiveInactiveIndicator isLive={true} />
          </Col>
          <Col className="text-dark font-size-14 font-weight-500">
            Live Generation
          </Col>
        </Row>
        <Row className="mt-2">
          {[...liveGenerationDate]
            .sort(
              (a, b) =>
                desiredOrder.indexOf(
                  a.powerGeneratorType as EEnergySourceType
                ) -
                desiredOrder.indexOf(b.powerGeneratorType as EEnergySourceType)
            )
            .map((liveData, index) => {
              return (
                <Col
                  key={index}
                  className={`${
                    index === 0
                      ? `col-12 col-xl-4 mb-4 mb-xl-0`
                      : `col-12 col-sm-6 col-xl-4`
                  } ${index === 1 && `mb-4 mb-sm-0`}`}
                >
                  <Row>
                    <Col>
                      <EnergySourceInfoCardWithIndicator
                        title={findEnergySourceType(
                          liveData.powerGeneratorType as EEnergySourceType
                        )}
                        value={`${Number(
                          Helper.roundTo2(liveData.generatedEnergy / 1000)
                        )} kW`}
                        icon={energySourceIconType(
                          liveData.powerGeneratorType as EEnergySourceType
                        )}
                        powerGeneratorType={
                          liveData.powerGeneratorType as EEnergySourceType
                        }
                        isFetching={
                          isFetchingLiveEnergyGenerationData && isFirstTime
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <ExpectedPowerValue
                        title={`Expected ${findEnergySourceType(
                          liveData.powerGeneratorType as EEnergySourceType
                        )} Power`}
                        value={
                          Number(
                            Helper.roundTo2(
                              powerConvertor(
                                liveData.hourlyPredictedEnergy,
                                EPowerMeasureType.KILO_WATT
                              )
                            )
                          ) || 0
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>
      </div>
      <div className="container-white position-relative mt-4">
        <Row className="align-items-center">
          <Col className="font-weight-500 font-size-14 text-dark">
            Recent{" "}
            {selectedUnitType.value === EUnitType.POWER ? `Power` : `Energy`}{" "}
            Generation History
          </Col>
          <Col className="col-12 col-sm-auto mt-2 mt-sm-0">
            <Row className="align-items-center justify-content-between">
              <Col className="col-auto">
                <AppSelect
                  className="mt-1"
                  selectedValue={{ ...selectedUnitType }}
                  options={[...unitTypes]}
                  onChangeOption={(selectedOption) => {
                    setSelectedUnitType(selectedOption);
                  }}
                  id="unit-type"
                />
              </Col>
              <Col className="col-auto ps-1">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => {
                    setShowFilter(true);
                    setIsTotalGenerationFilter(false);
                  }}
                  badgeValue={0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <AreaChart
          borderColor1="#2DEC9F"
          borderColor2="#2EB5ED"
          borderColor3="#7C0F0F"
          backgroundColor1="#29CC3917"
          backgroundColor2="#DFFDFB4D"
          backgroundColor3="#F8F8F8"
          label1="Solar Power"
          label2="Wind Power"
          label3="Generator"
          yAxesUnit={selectedUnitType.value === EUnitType.POWER ? `kW` : `kWh`}
          labels={chartData.labels}
          data1={chartData.solarData}
          data2={chartData.windTurbineData}
          data3={chartData.generatorData}
          hasThirdDataSet={true}
          isChartDataAvailable={true}
          xAxisLabel={
            generationFilterStore.selectedDateType === EDateTypes.MONTHLY
              ? "Days"
              : "Hours"
          }
        />
        <Row className="mt-3">
          <Col className="text-light text-center font-size-12 font-weight-500">
            {selectedUnitType.value === EUnitType.POWER ? `Power` : `Energy`}{" "}
            Generation
          </Col>
        </Row>
        <SpinnerModal show={isFetchingEnergyGenerationData} />
      </div>
      <div className="mt-4">
        <Row>
          <Col>
            <EnergySourceInfoCardWithIndicator
              title="Battery"
              value={
                <>
                  {batteryData?.batteryPercentage} %{" "}
                  <span className="text-light font-weight-500">{`(${batteryData?.batteryCapacity}kWh)`}</span>
                </>
              }
              icon="battery_charging_80"
              powerGeneratorType={EEnergySourceType.BATTERY}
              isFetching={isFetchingViewBatteryInformation}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <ExpectedPowerValue
              title="Expected Battery Charger"
              value={batteryData?.expectedBatteryCharge || 0}
              isEnergy={false}
            />
          </Col>
        </Row>
      </div>
      <div className="container-white position-relative mt-4">
        <Row className="justify-content-end">
          <Col className="col-auto">
            <ButtonWithBadge
              text="Filter"
              icon="filter_alt"
              onClick={() => {
                console.log("Filter");
              }}
              badgeValue={0}
            />
          </Col>
        </Row>
        <AreaChart
          borderColor1="#A19B12"
          backgroundColor1="#CCBC2917"
          label1="Battery Backup (50 kWh)"
          yAxesUnit="%"
          labels={monthList}
          data1={batteryBackupChartData}
          isChartDataAvailable={true}
        />
        <Row className="mt-3">
          <Col className="text-light text-center font-size-12 font-weight-500">
            Battery Levels
          </Col>
        </Row>
        <SpinnerModal show={isFetchingBatteryBackupData} />
      </div>
      <GenerationFilter
        showFilter={showFilter}
        setShowFilter={(value: boolean) => {
          setShowFilter(value);
        }}
        onFilter={onFilter}
        isTotalGenerationFilter={isTotalGenerationFilter}
        selectedDate={generationFilterStore.selectedDate}
        selectedTotalGenerationDate={
          generationFilterStore.selectedTotalGenerationDate
        }
        selectedDateType={generationFilterStore.selectedDateType}
        onClearAll={onClearAll}
      />
    </>
  );
};

export default Generation;
