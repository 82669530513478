import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyViewCurrentEnergySourceDataQuery,
  useLazyViewLatestEnergyStatisticDataQuery,
  useLazyViewPowerConsumersWithHighConsumptionQuery,
  useLazyViewSpaceClusterConsumptionByYearAndMonthQuery,
} from "../../../redux/api/dashboard/dashboardAPI";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import { useLazyViewLatestUsageBySourceQuery } from "../../../redux/api/usage/usageAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import EnergySourceCard from "../../../shared/components/energy-source-card/energy-source-card";
import MiniGrid from "../../../shared/components/mini-grid/mini-grid";
import UsageComparisonCard from "../../../shared/components/usage-comparison-card/usage-comparison-card";
import {
  ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import ViewCurrentEnergySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-current-energy-source-response-dto";
import ViewLatestEnergyStatisticsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-latest-energy-statistics-response-dto";
import ViewLatestUsageBySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-latest-usage-by-source-response-dto";
import ViewScheduledPowerConsumersSortedByEnergyResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-scheduled-power-consumers-sorted-by-energy-response-dto";
import { EConsumptionValueComparison } from "../../../shared/oversight-core/enums/consumption-comparison";
import { EEnergyViewType } from "../../../shared/oversight-core/enums/energy-viewType";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { EPowerMeasureType } from "../../../shared/oversight-core/enums/power-measure-type";
import { ESortOrder } from "../../../shared/oversight-core/enums/sort-order";
import { EUsageDataFrom } from "../../../shared/oversight-core/enums/usage-data-from";
import { EViewType } from "../../../shared/oversight-core/enums/view-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IEnergySourceView } from "../../../shared/oversight-core/interfaces/energy-source-view";
import IExtendedEnergyConsumers from "../../../shared/oversight-core/interfaces/extended-energy-consumers";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import { IUsageBySourceView } from "../../../shared/oversight-core/interfaces/usage-by-source-view";
import UsageCard from "../../../shared/oversight-core/shared-components/dashboard-widgets/usage/usage-card";
import HighPowerDevices from "../../../shared/oversight-core/shared-pages/dashboard-widgets/high-power-devices/high-power-devices";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import InfoAndValue from "../../../shared/oversight-core/ui-elements/info-and-value/info-and-value";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { devicePath } from "../../../shared/oversight-core/utils/device-path";
import Helper from "../../../shared/oversight-core/utils/helpers";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import { powerConvertor } from "../../../shared/oversight-core/utils/power-convertor";
import { showErrorMessage } from "../../../shared/oversight-core/utils/toast";
import LiveUsage from "../usage/components/live-usage/live-usage";

const defaultUsage = {
  energyBill: 0,
  energyInUnits: 0,
};

const defaultEnergyStaticData = {
  usage: 0,
  solarGeneration: 0,
  gridExport: 0,
  gridImport: 0,
  windGeneration: 0,
};

const defaultLatestUsageSource: IUsageBySourceView = {
  demand: 0,
  batteryPercentage: 0,
  gridUsage: 0,
  gridExport: 0,
  generator: 0,
  solarPV: 0,
  windTurbine: 0,
  batteryCharge: 0,
  batteryDischarge: 0,
  demandFromSolarGeneration: 0,
  isUsingBattery: false,
  isUsingSolarPV: false,
  isUsingWindTurbine: false,
  isUsingGenerator: false,
  isChargingBatterySolarPV: false,
  isChargingBatteryWindTurbine: false,
  isImportingFromGrid: false,
  isExportingToGrid: false,
};

const viewTypes = [
  { name: "Consumed", type: EViewType.CONSUMED },
  { name: "Manually Scheduled", type: EViewType.MANUALLY_SCHEDULED },
  {
    name: "Semi-Automatically Scheduled",
    type: EViewType.SEMI_AUTOMATICALLY_SCHEDULED,
  },
];

const Home = () => {
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);

  const [semiAutomatedUsageThisMonth, setSemiAutomatedUsageThisMonth] =
    useState({
      ...defaultUsage,
    });
  const [semiAutomatedUsageLastMonth, setSemiAutomatedUsageLastMonth] =
    useState({
      ...defaultUsage,
    });
  const [actualThisMonth, setActualThisMonth] = useState({
    ...defaultUsage,
  });
  const [actualLastMonth, setActualLastMonth] = useState({
    ...defaultUsage,
  });
  const [consumers, setConsumers] = useState<IExtendedEnergyConsumers[]>([]);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [comparisonEnergyThisMonth, setComparisonEnergyThisMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [comparisonEnergyLastMonth, setComparisonEnergyLastMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [actualUsageDataFromThisMonth, setActualUsageDataFromThisMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [actualUsageDataFromLastMonth, setActualUsageDataFromLastMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [latestEnergyStatisticData, setLatestEnergyStatisticData] =
    useState<ViewLatestEnergyStatisticsResponseDTO>({
      ...defaultEnergyStaticData,
    });
  const [currentEnergySourceData, setCurrentEnergySourceData] =
    useState<IEnergySourceView>();
  const [currentViewType, setCurrentViewType] = useState(0);
  const [latestUsageSource, setLatestUsageSource] =
    useState<IUsageBySourceView>({ ...defaultLatestUsageSource });
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [previousEnergyUnits, setPreviousEnergyUnits] = useState(0);

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpace }] =
    useLazyGetSpacesQuery();
  const [triggerGetActualUsage, { isFetching: isFetchingActualUsage }] =
    useLazyViewSpaceClusterConsumptionByYearAndMonthQuery();
  const [
    triggerGetSemiAutomatedUsage,
    { isFetching: isFetchingSemiAutomatedUsage },
  ] = useLazyGetSpaceClusterEnergyWithComparisonQuery();
  const [
    triggerViewPowerConsumersWithHighConsumption,
    { isFetching: isFetchingHighestPowerConsumers },
  ] = useLazyViewPowerConsumersWithHighConsumptionQuery();
  const [
    triggerLatestEnergyStatisticData,
    { isFetching: isFetchingLatestEnergyStatisticData },
  ] = useLazyViewLatestEnergyStatisticDataQuery();
  const [
    triggerViewCurrentEnergySourceData,
    { isFetching: isFetchingViewCurrentEnergySourceData },
  ] = useLazyViewCurrentEnergySourceDataQuery();
  const [
    triggerLatestUsageBySource,
    { isFetching: isFetchingLatestUsageBySource },
  ] = useLazyViewLatestUsageBySourceQuery();

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerGetActualUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      })
        .unwrap()
        .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
          setActualThisMonth({
            energyBill: res.energy.energyBill,
            energyInUnits: res.energy.energyInUnits,
          });
          setPreviousEnergyUnits(res.previousEnergy.energyInUnits);
          setComparisonEnergyThisMonth(
            res.comparedToPreviousMonthConsumedEnergy
          );
          setActualUsageDataFromThisMonth(res.usageDataFrom);
        })
        .catch(() => {
          setActualThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setPreviousEnergyUnits(0);
          setComparisonEnergyThisMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerGetActualUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      })
        .unwrap()
        .then((res: ViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
          setComparisonEnergyLastMonth(
            res.comparedToPreviousMonthConsumedEnergy
          );
          setActualLastMonth({
            energyBill: res.energy.energyBill,
            energyInUnits: res.energy.energyInUnits,
          });
          setActualUsageDataFromLastMonth(res.usageDataFrom);
        })
        .catch(() => {
          setActualLastMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setComparisonEnergyLastMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      triggerGetSemiAutomatedUsage({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewSpaceClusterEnergyWithComparisonResponseDTO) => {
          setSemiAutomatedUsageThisMonth({
            energyBill: res.currentMonthEnergyView.energyBill,
            energyInUnits: res.currentMonthEnergyView.energyInUnits,
          });
          setSemiAutomatedUsageLastMonth({
            energyBill: res.previousMonthEnergyView.energyBill,
            energyInUnits: res.previousMonthEnergyView.energyInUnits,
          });
        })
        .catch(() => {
          setSemiAutomatedUsageThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setSemiAutomatedUsageLastMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    if (billingSpaceFilter.spaceCluster.id) {
      setConsumers([]);
      triggerViewPowerConsumersWithHighConsumption({
        clusterId: billingSpaceFilter.spaceCluster.id,
        sortingOrder: ESortOrder.DESC,
        limit: 6,
        energyViewType: EEnergyViewType.ALL,
        viewType: viewTypes[currentViewType].type,
        year: moment().year(),
        month: moment().month() + 1,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewScheduledPowerConsumersSortedByEnergyResponseDTO) => {
          const extendedPowerConsumers = res.powerConsumers
            ? res.powerConsumers.map((powerConsumer) => {
                const path = devicePath(res?.devicePaths[powerConsumer.id]);
                return { ...powerConsumer, path };
              })
            : [];
          setConsumers(extendedPowerConsumers);
        })
        .catch((error) => {
          if (
            error.status !== 404 &&
            error.ovstErrorCode !== OvstErrorCode.OVST_0028
          ) {
            showErrorMessage("Sorry, An error occurred");
            return;
          }
        });
    }
  }, [billingSpaceFilter.spaceCluster.id]);

  useEffect(() => {
    triggerLatestEnergyStatisticData()
      .unwrap()
      .then((res: ViewLatestEnergyStatisticsResponseDTO) => {
        setLatestEnergyStatisticData(res);
        setIsFirstTime(false);
      })
      .catch(() => {
        setLatestEnergyStatisticData(defaultEnergyStaticData);
      });
  }, [triggerLatestEnergyStatisticData]);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerLatestEnergyStatisticData()
        .unwrap()
        .then((res: ViewLatestEnergyStatisticsResponseDTO) => {
          setLatestEnergyStatisticData(res);
          setIsFirstTime(false);
        })
        .catch(() => {
          setLatestEnergyStatisticData(defaultEnergyStaticData);
        });
    }, 20000);
    return () => clearInterval(interval);
  }, [triggerLatestEnergyStatisticData]);

  useEffect(() => {
    triggerViewCurrentEnergySourceData()
      .unwrap()
      .then((res: ViewCurrentEnergySourceResponseDTO) => {
        setCurrentEnergySourceData(res.energySourceView);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerViewCurrentEnergySourceData]);

  useEffect(() => {
    triggerLatestUsageBySource()
      .unwrap()
      .then((res: ViewLatestUsageBySourceResponseDTO) => {
        console.log(res, "buddy buddy");
        setLatestUsageSource(res.usageView);
        setIsFirstTime(false);
      })
      .catch(() => {
        setLatestUsageSource({ ...defaultLatestUsageSource });
      });
  }, [triggerLatestUsageBySource]);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerLatestUsageBySource()
        .unwrap()
        .then((res: ViewLatestUsageBySourceResponseDTO) => {
          setLatestUsageSource(res.usageView);
          setIsFirstTime(false);
        })
        .catch(() => {
          setLatestUsageSource({ ...defaultLatestUsageSource });
        });
    }, 20000);
    return () => clearInterval(interval);
  }, [triggerLatestUsageBySource]);

  const comparisonCardTitle = () => {
    const now = new Date();
    const daysInCurrentMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0
    ).getDate();

    if (now.getDate() === 1) {
      return "First day";
    } else if (now.getDate() === daysInCurrentMonth) {
      return "End of Month";
    } else {
      return `${now.getDate()} Days`;
    }
  };

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 ? (
        <>
          <div className="container-white">
            <Row className="justify-content-end">
              <Col
                xs={12}
                xl={9}
                className={"d-flex align-items-center justify-content-center"}
              >
                {/*{!isLoaded && (*/}
                {/*  <SpinnerModal*/}
                {/*    show*/}
                {/*    withOverlay={false}*/}
                {/*    loaderText="Loading Mini-Grid Digital Twin"*/}
                {/*    loadingPercentage={(loadingProgression * 100).toFixed(2)}*/}
                {/*  />*/}
                {/*)}*/}
                {/*<Unity*/}
                {/*  unityProvider={unityProvider}*/}
                {/*  style={{ width: "100%" }}*/}
                {/*/>*/}
                <MiniGrid
                  solar={
                    +Helper.roundTo2(
                      powerConvertor(
                        latestEnergyStatisticData.solarGeneration,
                        EPowerMeasureType.KILO_WATT
                      )
                    )
                  }
                  wind={
                    +Helper.roundTo2(
                      powerConvertor(
                        latestEnergyStatisticData.windGeneration,
                        EPowerMeasureType.KILO_WATT
                      )
                    )
                  }
                  generator={0}
                  battery={latestUsageSource.batteryPercentage}
                  demand={
                    +Helper.roundTo2(
                      powerConvertor(
                        latestEnergyStatisticData.usage,
                        EPowerMeasureType.KILO_WATT
                      )
                    )
                  }
                  gridUsage={
                    latestEnergyStatisticData.gridImport > 0
                      ? +Helper.roundTo2(
                          powerConvertor(
                            latestEnergyStatisticData.gridImport,
                            EPowerMeasureType.KILO_WATT
                          )
                        )
                      : -+Helper.roundTo2(
                          powerConvertor(
                            latestEnergyStatisticData.gridExport,
                            EPowerMeasureType.KILO_WATT
                          )
                        )
                  }
                  girdDirection={
                    latestEnergyStatisticData.gridImport > 0
                      ? "Usage"
                      : latestEnergyStatisticData.gridExport > 0
                      ? "Export"
                      : "Nothing"
                  }
                />
              </Col>
              <Col className="col-12 col-xl-3 mt-4 mt-xl-0">
                <Row className="align-items-center mb-3">
                  <Col className="col-auto pe-0">
                    <ActiveInactiveIndicator isLive={true} />
                  </Col>
                  <Col className="text-light font-weight-500 font-size-12">
                    Live Demand and Supply Breakdown
                  </Col>
                </Row>
                <Row className="flex-row flex-xl-column justify-content-between gy-4">
                  <Col className="col-6 col-lg-3 col-xl-12">
                    <InfoAndValue
                      icon="electric_meter"
                      title="Demand"
                      value={`${Helper.roundTo2(
                        powerConvertor(
                          latestEnergyStatisticData.usage,
                          EPowerMeasureType.KILO_WATT
                        )
                      )} kW`}
                      isFetching={
                        isFetchingLatestEnergyStatisticData && isFirstTime
                      }
                    />
                  </Col>
                  <Col className="col-6 col-lg-3 col-xl-12">
                    <InfoAndValue
                      icon="sunny"
                      title="Generation"
                      value={`${Helper.roundTo2(
                        powerConvertor(
                          latestEnergyStatisticData.solarGeneration,
                          EPowerMeasureType.KILO_WATT
                        )
                      )} kW`}
                      isFetching={
                        isFetchingLatestEnergyStatisticData && isFirstTime
                      }
                    />
                  </Col>
                  <Col className="col-6 col-lg-3 col-xl-12">
                    <InfoAndValue
                      icon="charger"
                      title="Usage from Grid"
                      value={`${Helper.roundTo2(
                        powerConvertor(
                          latestEnergyStatisticData.gridImport,
                          EPowerMeasureType.KILO_WATT
                        )
                      )} kW`}
                      isFetching={
                        isFetchingLatestEnergyStatisticData && isFirstTime
                      }
                    />
                  </Col>
                  <Col className="col-6 col-lg-3 col-xl-12">
                    <InfoAndValue
                      icon="arrow_upward"
                      title="Export to Grid"
                      value={`${Helper.roundTo2(
                        powerConvertor(
                          latestEnergyStatisticData?.gridExport,
                          EPowerMeasureType.KILO_WATT
                        )
                      )} kW`}
                      isFetching={
                        isFetchingLatestEnergyStatisticData && isFirstTime
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="mt-4">
            <Col className="col-12 col-xl-6">
              <div className="container-white h-100">
                <Row className="mt-2 align-items-center">
                  <Col className="col-auto pe-0">
                    <ActiveInactiveIndicator isLive={true} />
                  </Col>
                  <Col className="text-light font-weight-500 font-size-12">
                    Live Power Flow
                  </Col>
                </Row>
                {isFetchingLatestUsageBySource && isFirstTime ? (
                  <SpinnerModal
                    show={isFetchingLatestUsageBySource && isFirstTime}
                    withOverlay={false}
                  />
                ) : (
                  <>
                    {/* {energyUsageBySourceList.length > 0 ? (
                      <DoughnutChart
                        dataset={energyUsageBySourceList.map(
                          (eu) => eu.percentage
                        )}
                        backgroundColors={energyUsageBySourceList.map((eu) =>
                          energySourceIconColor(eu.energySourceType)
                        )}
                        borderColors={energyUsageBySourceList.map((eu) =>
                          energySourceIconColor(eu.energySourceType)
                        )}
                        formatValue={(value: number): string => {
                          return `${value}%`;
                        }}
                        formatLabel={(tooltipItem: any) => {
                          const label = tooltipItem.dataset.label || "";
                          const value = tooltipItem.raw;
                          return `${label}: ${value}%`;
                        }}
                        labelComponents={energyUsageBySourceList.map((eu) => ({
                          label: findEnergySourceType(eu.energySourceType),
                          icon: energySourceIconType(eu.energySourceType),
                          iconColor: energySourceIconColor(eu.energySourceType),
                          labelBackgroundColor: energySourceBackgroundColor(
                            eu.energySourceType
                          ),
                        }))}
                      />
                    ) : (
                      <div className="text-center mt-5 text-light font-weight-400 font-size-14">
                        No usage by type today
                      </div>
                    )} */}
                    <Row className="mt-4">
                      <Col className="d-flex justify-content-center">
                        <LiveUsage usageView={latestUsageSource} />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Col>
            <Col className="col-12 col-xl-6 mt-4 mt-xl-0">
              <EnergySourceCard
                title="Power Source Selection"
                subTitle="Last Power Source Selection Information"
                time={currentEnergySourceData?.dateTime || ""}
                expectedUsage={currentEnergySourceData?.consumedEnergy || 0}
                expectedGeneration={
                  currentEnergySourceData?.generatedEnergy || 0
                }
                exportUsage={currentEnergySourceData?.exportedEnergy || 0}
                nextSourceSelectionDateTime={
                  currentEnergySourceData?.nextSourceSelectionDateTime || ""
                }
                previousEnergySources={
                  currentEnergySourceData?.previousEnergySources || []
                }
                currentEnergySources={
                  currentEnergySourceData?.currentEnergySources || []
                }
                demandValueComparison={
                  currentEnergySourceData?.demandValueComparison || ""
                }
                generationValueComparison={
                  currentEnergySourceData?.generationValueComparison || ""
                }
                isFetching={isFetchingViewCurrentEnergySourceData}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col
              className="col-12 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="h-50">
                <UsageCard
                  title="Usage This Month"
                  subTitle1="Actual Usage"
                  subTitle2="Scheduled Usage"
                  value1={actualThisMonth}
                  value2={semiAutomatedUsageThisMonth}
                  isFetching={
                    isFetchingSemiAutomatedUsage || isFetchingActualUsage
                  }
                  consumptionValueComparison={comparisonEnergyThisMonth}
                  usageDataFrom={actualUsageDataFromThisMonth}
                />
              </div>
              <div className="h-50 mt-3 ">
                <UsageCard
                  title="Usage Last Month"
                  subTitle1="Actual Usage"
                  subTitle2="Scheduled Usage"
                  value1={actualLastMonth}
                  value2={semiAutomatedUsageLastMonth}
                  isFetching={
                    isFetchingSemiAutomatedUsage || isFetchingActualUsage
                  }
                  usageDataFrom={actualUsageDataFromLastMonth}
                  consumptionValueComparison={comparisonEnergyLastMonth}
                />
              </div>
            </Col>
            <Col className="col-12 col-xl-2 mt-3 mt-xl-0">
              <UsageComparisonCard
                title={`Usage in ${comparisonCardTitle()} `}
                thisMonthUnits={actualThisMonth.energyInUnits}
                lastMonthUnits={previousEnergyUnits}
                thisMonthTitle={`${comparisonCardTitle()}  this month`}
                lastMonthTitle={`${comparisonCardTitle()}  last month`}
                isFetching={
                  isFetchingSemiAutomatedUsage || isFetchingActualUsage
                }
                consumptionValueComparison={comparisonEnergyThisMonth}
                usageDataFrom={actualUsageDataFromThisMonth}
              />
            </Col>
            <Col className="mt-3 mt-xl-0 col-12 col-xl-6">
              <HighPowerDevices
                consumers={consumers || null}
                title={`Devices with Highest Energy Consumption this Month [${viewTypes[currentViewType].name}]`}
                isFetching={isFetchingHighestPowerConsumers}
                viewTypes={viewTypes}
                currentViewType={currentViewType}
                setCurrentViewType={setCurrentViewType}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          {!isFetchingSpace && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}

      <SpinnerModal show={isFetchingSpace} />
    </div>
  );
};

export default Home;
